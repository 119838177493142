<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
      >
        <template slot="extraSubtitle">
          <v-chip
            label
            small
            :color="formattedItem.color"
            text-color="white"
          >
            {{ formattedItem.estadoLlavero }}
          </v-chip>
        </template>
      </b10-view-summary>
      <b10-fab-button
        v-if="formattedItem.estado === LLAVERO.estados.enTablero"
        :icon="$vuetify.icons.values.salida"
        @click="clickSalida"
      />
      <b10-fab-button
        v-if="formattedItem.estado === LLAVERO.estados.retirado"
        :icon="$vuetify.icons.values.entrada"
        @click="clickEntrada"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import { nonEmpty } from '@/utils/templates'
import Data from './LlaveroViewData'
import { LLAVERO } from '@/utils/consts'
import colors from 'vuetify/lib/util/colors'

export default {
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      moreInfoRows: [
        'codigo',
        'mensaje',
        'observaciones',
        'cantalarmas',
        'cantllaves',
        'cantmandos',
      ],
      showingDialogs: {
        moreInfo: false,
      },
      LLAVERO,
    }
  },
  computed: {
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.idllavero
        item.title = `Llavero en posición ${item.posicion}`
        item.subtitle = item.observaciones
        item.estadoLlavero = item.estado === LLAVERO.estados.retirado ? 'Retirado' : 'En tablero'
        if (item.estado === LLAVERO.estados.retirado) {
          item.color = colors.red.base
        } else {
          item.color = colors.green.base
        }
        if (item.mensaje) {
          item.alerts = [{ value: item.mensaje, type: 'warning' }]
        } else {
          item.alerts = []
        }
        item.moreInfo = this.buildMoreInfo(this.item.metadata)
        return item
      } else {
        return {}
      }
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = nonEmpty`Llavero ${this.item.dataset.codigo}`
    },
    async loadItem () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectLlavero(this, this.routeParams.idllavero)
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.$set(this.item, 'metadata', metadata)
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    clickEntrada () {
      this.$appRouter.push({
        name: 'custodiallaves__llavero-entrada',
        params: {
          idllavero: this.routeParams.idllavero
        }
      })
    },
    clickSalida () {
      this.$appRouter.push({
        name: 'custodiallaves__llavero-salida',
        params: {
          idllavero: this.routeParams.idllavero
        }
      })
    },
  }
}
</script>
